import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Route, HashRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import configStore from './store/store.js'
import '../node_modules/toastr/build/toastr.min.css';
import axios from 'axios';

import MainComponent from './MainComponent/MainComponent';

const store = configStore;

export default class App extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        if (process.env.NODE_ENV === 'production') {
            this.disableReactDevTools();
        }
    }
    
    componentDidMount() {
        
    }

    componentWillUnmount() {
       
    }

    disableReactDevTools = (): void => {
        const noop = (): void => undefined;
        const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

        if (typeof DEV_TOOLS === 'object') {
            for (const [key, value] of (Object as any).entries(DEV_TOOLS)) {
                DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
            }
        }
    };

    render() {
        return (
            <div className="App">
                <MainComponent></MainComponent>
            </div>     
        );
    }
}

ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
           <App />
        </HashRouter>
    </Provider>, document.getElementById('root'));
